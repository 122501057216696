import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
import './../sass/style.scss';

'use strict';

window.addEventListener('DOMContentLoaded', () => {

    function debounce(func, wait, immediate) {
        let timeout;

        return function executedFunction() {
            const context = this;
            const args = arguments;

            const later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };

            const callNow = immediate && !timeout;

            clearTimeout(timeout);

            timeout = setTimeout(later, wait);

            if (callNow) func.apply(context, args);
        };
    };

    function changeAboutItemsPosition({ item, itemBox, newItemBox, position, mediaWidth }) {
        const parentElement = document.querySelector(`${itemBox}`);
        const newParentElement = document.querySelector(`${newItemBox}`);
        const element = document.querySelector(`.${item}`);

        if (window.innerWidth < mediaWidth) {
            if (position === 2) {
                element.style.margin = '0 auto';
                newParentElement.insertBefore(
                    element,
                    newParentElement
                        .firstElementChild
                        .nextElementSibling);
            }
        } else {
            element.removeAttribute('style');
            parentElement.append(element);
        }

    }

    function scrollBodyHidden() {
        const getScrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
        if (!document.body.classList.contains('scroll-hidden')) {
            document.body.classList.add('scroll-hidden');
            // document.querySelector('body').style.width = `100vw`;
            // document.querySelector('body').style.paddingRight = `${getScrollbarWidth}px`;
        } else {
            document.body.classList.remove('scroll-hidden');
        }
    }

    function openNav() {

        const nav = document.querySelector('.header-nav');
        const navList = document.querySelector('.header-nav__list');
        let timer;

        navList.style.transition = '0.3s';
        navList.style.transitionDelay = '0.15s';

        if (!nav.classList.contains('header-nav--open')) {
            nav.style.zIndex = '3';
            nav.classList.add('header-nav--open');
        } else {
            nav.classList.remove('header-nav--open');
            timer = setTimeout(() => {
                nav.style.zIndex = '-1';
                clearTimeout(timer);
            }, 350);
        }
    }

    function createBurgerCloseIcon(btn) {
        if (!document.querySelector('.header-burger__close')) {
            const div = document.createElement('div');
            const i = document.createElement('i');
            let timer;

            div.classList.add('header-burger__close');
            div.append(i);
            btn.append(div);
            document.querySelector('.header-burger__close').classList.add('header-burger__close--show');
        } else {
            toggleBurgerCloseIcon();
        }
    }

    function toggleBurgerCloseIcon(btn) {
        const crostBtn = document.querySelector('.header-burger__close');
        if (!crostBtn) {
            crostBtn.classList.add('header-burger__close--show');
        } else {
            if (!crostBtn.classList.contains('header-burger__close--show')) {
                crostBtn.classList.add('header-burger__close--show');
            } else {
                crostBtn.classList.remove('header-burger__close--show');
            }
        }
    }

    function toggleBurger() {
        const burgerBtn = document.querySelector('.header-burger');
        const burgerBtnSpan = document.querySelector('.header-burger__icon');
        let timer;

        if (!burgerBtn.classList.contains('header-burger--open')) {
            burgerBtn.classList.add('header-burger--open');
            burgerBtnSpan.classList.add('header-burger__icon--right');
            burgerBtnSpan.classList.add('header-burger__icon--square');
            createBurgerCloseIcon(burgerBtnSpan);
        } else {
            timer = setTimeout(() => {
                burgerBtn.classList.remove('header-burger--open');
                if (burgerBtnSpan.classList.contains('header-burger__icon--right')) {
                    scrollBurgerIcon();
                } else {
                    burgerBtnSpan.classList.remove('header-burger__icon--right');
                }
                burgerBtnSpan.classList.remove('header-burger__icon--square');
                toggleBurgerCloseIcon();
                clearTimeout(timer);
            }, 150);
        }
    }
    // function testClick(e) {
    //     alert(e.target);
    // }

    function toggleMenu(e, type) {

        if (window.innerWidth < 1024) {

            if (type === 'action') {
                if (e.target
                    && e.target.parentElement !== null
                    && e.target.classList.contains('header-burger-tap')
                    && !document.querySelector('.header-nav').classList.contains('header-nav--open')) {
                    openNav();
                    toggleBurger();
                    scrollBodyHidden();
                } else if (e.target
                    && e.target.parentElement !== null
                    && e.target.classList.contains('header-burger-tap')
                    && document.querySelector('.header-nav').classList.contains('header-nav--open')) {
                    openNav();
                    toggleBurger();
                    scrollBodyHidden();
                } else if (e.target
                    && e.target.classList.contains('header-nav__list-link')) {
                    openNav();
                    toggleBurger();
                    scrollBodyHidden();
                }
            } else {
                if (document.querySelector('.header-nav').classList.contains('header-nav--open')) {
                    openNav();
                    toggleBurger();
                    scrollBodyHidden();
                }
            }
        } else {
            if (document.querySelector('.header-nav').getAttribute('style') !== null) {
                document.querySelector('.header-nav').removeAttribute('style');
            }
        }
    }

    function scrollBurgerIcon() {
        if (window.innerWidth < 1024) {
            const burgerBtn = document.querySelector('.header-burger');
            const burgerBtnSpan = document.querySelector('.header-burger__icon');
            const main = document.querySelector('.header-logo');


            const options = {
                rootMargin: `-${burgerBtnSpan.clientHeight * 2}px 0px -${burgerBtnSpan.clientHeight * 2}px 0px`,
                threshold: [0, 1]
            };


            const trueCallback = function (entries, observer) {
                entries.forEach((entry) => {
                    if (!entry.isIntersecting) {
                        burgerBtnSpan.classList.add('header-burger__icon--right');
                    } else {
                        burgerBtnSpan.classList.remove('header-burger__icon--right');
                    }
                });
            };

            const target = burgerBtn.firstElementChild;
            const observer = new IntersectionObserver(trueCallback, options);
            observer.observe(target);
        } else {
            const burgerBtnSpan = document.querySelector('.header-burger__icon');
            burgerBtnSpan.classList.remove('header-burger__icon--right');
        }
    }








    function slider() {
        const galleryThumbs = new Swiper(".gallery-thumbs", {
            centeredSlides: false,
            centeredSlidesBounds: true,
            direction: "horizontal",
            spaceBetween: 10,
            slidesPerView: 4,
            freeMode: false,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            watchOverflow: true,
            breakpoints: {
                1023: {
                    direction: "vertical",
                    slidesPerView: 4
                }
            }
        });
        const galleryTop = new Swiper(".gallery-top", {
            direction: "horizontal",
            spaceBetween: 10,
            zoom: true,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev"
            },
            a11y: {
                prevSlideMessage: "Previous slide",
                nextSlideMessage: "Next slide",
            },
            keyboard: {
                enabled: true,
            },
            thumbs: {
                swiper: galleryThumbs
            }
        });
        document.addEventListener("slideChangeTransitionStart", function () {
            galleryThumbs.slideTo(galleryTop.activeIndex);
        });
        document.addEventListener("transitionStart", function () {
            galleryTop.slideTo(galleryThumbs.activeIndex);
        });
    };

    function setBodyWidth() {
        document.body.style.width = `calc(100vw - ${window.innerWidth - document.documentElement.clientWidth}px)`;
    }
    setBodyWidth();
    slider();

    scrollBurgerIcon();

    changeAboutItemsPosition({
        item: 'about__item-img',
        itemBox: '[data-about-box="1"]',
        newItemBox: '[data-about-box="2"]',
        position: 2,
        mediaWidth: 768
    });

    document.addEventListener('click', (e) => {
        toggleMenu(e, 'action');
    });

    window.addEventListener('resize', (e) => {
        setBodyWidth();
        toggleMenu(null, 'resize');

        scrollBurgerIcon();
        changeAboutItemsPosition({
            item: 'about__item-img',
            itemBox: '[data-about-box="1"]',
            newItemBox: '[data-about-box="2"]',
            position: 2,
            mediaWidth: 768
        });
    });

});
